import http from '@/utils/http.js'

// 客户列表
export function customerList(data) {
    return http({
        url: "/jasoboss/customer/list",
        method: "post",
        data
    })
}
// 客户往来添加
export function dealingAdd(data) {
    return http({
        url: "/jasoboss/customer/intercourse/add",
        method: "post",
        data
    })
}
// 客户往来列表
export function dealingList(data) {
    return http({
        url: "/jasoboss/customer/intercourse/list",
        method: "post",
        data
    })
}
// 客户往来添加说明
export function dealingDesAdd(data) {
    return http({
        url: "/jasoboss/customer/intercourse/describe/add",
        method: "post",
        data
    })
}
// 删除客户联系人
export function customerDelete(data) {
    return http({
        url: "/jasoboss/customer/contact/delete",
        method: "post",
        data
    })
}
// 根据角色数组查人员
export function roleList(data) {
    return http({
        url: "/jasoboss/admin/role/type/list",
        method: "post",
        data
    })
}


// 根据角色数组查人员
export function roleListAll(data) {
    return http({
        url: "/jasoboss/admin/role/type/list/all",
        method: "post",
        data
    })
}
// 获取企业name信息
export function nameGet(data) {
    return http({
        url: "/jasoboss/customer/enterprise/name/get",
        method: "post",
        data
    })
}
// 获取企业domain信息
export function domainGet(data) {
    return http({
        url: "/jasoboss/customer/enterprise/domain/get",
        method: "post",
        data
    })
}
// 强制获取企业domain信息
export function domainMustGet(data) {
    return http({
        url: "/jasoboss/customer/enterprise/must/domain/get",
        method: "post",
        data
    })
}
// 强制获取企业信息
export function nameMustGet(data) {
    return http({
        url: "/jasoboss/customer/enterprise/must/name/get",
        method: "post",
        data
    })
}
// 关联客户 删除原有的客户，关联新的客户
export function relationCustomer(data) {
    return http({
        url: "/jasoboss/customer/relation/customer",
        method: "post",
        data
    })
}
// 放弃客户
export function giveUpCustomer(data) {
    return http({
        url: "/jasoboss/customer/give/up/customer",
        method: "post",
        data
    })
}
// 本月客户统计
export function customerMonthBrief(data) {
    return http({
        url: "/jasoboss/customer/month/brief",
        method: "post",
        data
    })
}
// 添加客户联系方式
export function customerMainContactAdd(data) {
    return http({
        url: "/jasoboss/customer/main/contact/add",
        method: "post",
        data
    })
}
// 修改客户联系方式
export function customerMainContactUpdate(data) {
    return http({
        url: "/jasoboss/customer/main/contact/update",
        method: "post",
        data
    })
}
// 删除客户联系方式
export function customerMainContactDelete(data) {
    return http({
        url: "/jasoboss/customer/main/contact/delete",
        method: "post",
        data
    })
}
// 客户统计
export function customerBriefGet(data) {
    return http({
        url: '/jasoboss/customer/brief/get',
        method: "post",
        data
    })
}
export function customerBriefOrderList(data) {
    return http({
        url: '/jasoboss/customer/brief/order/list',
        method: "post",
        data
    })
}
export function customerBriefTradeList(data) {
    return http({
        url: "/jasoboss/customer/brief/trade/list",
        method: "post",
        data
    })
}
export function customerContactBriefList(data) {
    return http({
        url: "/jasoboss/customer/brief/contract/get",
        method: "post",
        data
    })
}

// 客户计划导入
export function customerRecordImport(data) {
    return http({
        url: "/jasoboss/customer/record/import",
        method: "post",
        data
    })
}


// 客户计划列表
export function customerRecordList(data) {
    return http({
        url: "/jasoboss/customer/record/list",
        method: "post",
        data
    })
}


// 客户计划分组列表
export function customerRecordGroupList(data) {
    return http({
        url: "/jasoboss/customer/record/group/list",
        method: "post",
        data
    })
}

// 客户计划列表
export function customerRecordHistory(data) {
    return http({
        url: "/jasoboss/customer/record/history/list",
        method: "post",
        data
    })
}
