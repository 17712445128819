import http from '@/utils/http.js'

// 回款列表
export function arriveList(data) {
    return http({
        url: "/jasoboss/arrive/list",
        method: "post",
        data
    })
}
// 新增
export function arriveAdd(data) {
    return http({
        url: "/jasoboss/arrive/add",
        method: "post",
        data
    })
}
// 编辑
export function arriveUpdate(data) {
    return http({
        url: "/jasoboss/arrive/update",
        method: "post",
        data
    })
}
// 单个
export function arriveGet(data) {
    return http({
        url: "/jasoboss/arrive/get",
        method: "post",
        data
    })
}
//回款列表上统计
export function statisticsList(data) {
    return http({
        url: "/jasoboss/arrive/list/statistics",
        method: "post",
        data
    })
}
// 历史
export function arriveHistory(data) {
    return http({
        url: "/jasoboss/arrive/history/list",
        method: "post",
        data
    })
}

//业务流水列表
export function businessFlowList(data) {
    return http({
        url: "/jasoboss/arrive/business/water/list",
        method: "post",
        data
    })
}
//业务流水统计
export function businessFlowStatistics(data) {
    return http({
        url: "/jasoboss/arrive/business/water/list/statistics",
        method: "post",
        data
    })
}


// 回款导入银行流水
export function arriveImport(data) {
    return http({
        url: "/jasoboss/arrive/import",
        method: "post",
        data
    })
}


