import http from '@/utils/http.js'

// 获取部门列表
export function departmentList(data) {
    return http({
        url: "/jasoboss/department/list",
        method: "post",
        data
    })
}
// 获取部门成员
export function userList(data) {
    return http({
        url: "/jasoboss/department/admin/list",
        method: "post",
        data
    })
}
// 编辑部门成员
export function editUser(data) {
    return http({
        url: "/jasoboss/admin/update",
        method: "post",
        data
    })
}
// 成员数据继承
export function inheritUser(data) {
    return http({
        url: "/jasoboss/admin/inherit",
        method: "post",
        data
    })
}

// 添加部门成员
export function addUser(data) {
    return http({
        url: "/jasoboss/admin/add",
        method: "post",
        data
    })
}
// 修改成员状态
export function statusUser(data) {
    return http({
        url: "/jasoboss/admin/update/status",
        method: "post",
        data
    })
}
// 新增部门
export function addDepartment(data) {
    return http({
        url: "/jasoboss/department/add",
        method: "post",
        data
    })
}
// 修改部门
export function editDepartment(data) {
    return http({
        url: "/jasoboss/department/update",
        method: "post",
        data
    })
}
// 删除部门
export function removeDepartment(data) {
    return http({
        url: "/jasoboss/department/delete",
        method: "post",
        data
    })
}
